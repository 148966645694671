/* COLORS */
$black: #000;
$white: #fff;
$sn_red: #fcb603;
$sn_cream: #fff7d2;
$grey: #666666;
$grey-lt: #cccccc;
$grey-dk: #303030;

/* FONTS */
$font_T: 'Conv_CocoBikeR', Arial, Helvetica, sans-serif;
$font_TR: 'Conv_CocoBikeR', Arial, Helvetica, sans-serif;
$font_TC: 'Montserrat', Arial, Helvetica, sans-serif;
$font_Courier: 'courier new', courier, monospace;
$font_basic: Helvetica, Arial, sans-serif;

/* BREAKPOINTS */
$tablet: 800px;
$wide-phone: 650px;
$phone: 500px;
$narrow-phone: 375px;
