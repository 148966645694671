html {
	overflow: hidden;
}
body {
	background: $grey-dk;
	color: $white;
	font: 400 16px/16px $font_T;
}
a {
	color: $sn_red;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		cursor: pointer;
	}
}
strong {
	font-weight: 700;
}
.clearFix {
	clear: both;
}
#fancybox-loading {
	display:none;
}
#bg-main {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: -1;
}
body.home #bg-main {
	background-position: center center;
}
#home-bg-main {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	z-index: -1;
}
#logo {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 830px;
	height: 74px;
	text-indent: -9999px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	@media screen and (max-width: 700px) {
		top: 14px;
		right: 60px;
		left: 20px;
		background-position: top center;
		width: auto;
	}
}
#main-nav {
	position: absolute;
	top: 96px;
	right: 10px;
	height: 60px;
	ul {

		li {
			display: inline-block;
			padding: 0 20px;
			a {
				font: 400 30px/36px $font_TR;
				color: $black;
				-webkit-transition: all 200ms ease;
				transition: all 200ms ease;
				&:hover {
					color: $sn_red;
				}
			}
			&.current-menu-item a {
				color: $sn_red;
				&:hover {
					color: $sn_red;
				}
			}
		}
	}
	.main-nav-shop {

	}
	@media screen and (max-width: 700px) {
		display: none;
	}
}
main {
	position: absolute;
	top: 140px;
	right: 10px;
	left: 10px;
	bottom: 75px;

	@media screen and (max-width: 700px) {
		top: 95px;
	}
}
.home main {
	bottom: 220px;
	left: auto;
	overflow: scroll;
	max-width: 532px;
    width: 100%;
	@media screen and (max-width: 700px) {
		bottom: 75px;
		width: 90%;
	}
}
.ctas {
	display: block;
	text-align: right;
	width: 100%;
	height: 120px;
	margin-bottom: 30px;
	.cta {
		display: inline-block;
		position: relative;
		vertical-align: top;
		height: 120px;
		width: 102px;
		margin-left: 20px;
		font: 400 24px/27px $font_TR;
		color: $black;
		padding-top: 66px;
		text-align: center;
		box-sizing: border-box;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		.cta_img {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 60px;
			background-position: center top;
			background-repeat: no-repeat;
			background-size: contain;
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 60px;
				background-position: center top;
				background-repeat: no-repeat;
				background-size: contain;
				opacity: 1;
				-webkit-transition: all 200ms ease;
				transition: all 200ms ease;
			}
		}
		&:hover {
			color: $sn_red;
			.cta_img:after {
				opacity: 0;
			}
		}
		&:last-of-type {
			letter-spacing: 0.07em;
			font-size: 26px;
			padding-top: 68px;
		}
	}
	@media screen and (max-width: 700px) {
		display: none;
	}
}
.home-main-content {
	display: block;
	right: 10px;
	width: 100%;
	text-align: right;
}
.info_block_wrap {
	height: 0;
	position: relative;
	margin-bottom: 10px;
	@media screen and (max-width: 700px) {
		background: rgba(255,255,255,0.5);
	}
}
.info_block {
	position: absolute;
	top:0;left:0;right:0;bottom:0;
	width: 100%;
	height: 100%;
	display: block;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
.main-content {
	display: block;
	right: 10px;
	width: 100%;
	text-align: right;
	width: 90%;
	background-color: rgba(0,0,0,0.5);
	border-radius: 10px;
	bottom: 0;
	top: 140px;
	position: absolute;
	max-width: 1100px;
	@media screen and (max-width: 700px) {
		top: 0;
	}
}
.content-title {
	background-color: $black;
	height: 50px;
	font: 400 55px/40px $font_TR;
	letter-spacing: 0.15em;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	padding-right: 20px;
	padding-top: 10px;
}
.content-copy {
	overflow: scroll;
	position: absolute;
	top: 50px;
	bottom: 0;
	right: 0;
	left: 0;
	padding: 20px;
	text-align: left;
	font: 400 14px/24px $font_Courier;
}
#social-links {
	position: absolute;
	bottom: 10px;
	right: 10px;
	display: block;
	text-align: right;
	width: 305px;
	height: 50px;
	.social {
		display: inline-block;
		position: relative;
		vertical-align: top;
		height: 50px;
		width: 50px;
		margin-left: 10px;
		text-indent: -9999px;
		text-align: center;
		.social_img {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 50px;
			background-position: center top;
			background-repeat: no-repeat;
			background-size: contain;
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 50px;
				background-position: center top;
				background-repeat: no-repeat;
				background-size: contain;
				opacity: 1;
				-webkit-transition: all 200ms ease;
				transition: all 200ms ease;
			}
		}
		&:hover .social_img:after {
			opacity: 0.3;
		}
	}
}
.home #social-links {
	bottom: 160px;
	@media screen and (max-width: 700px) {
		bottom: 10px;
	}
}
#the-drip {
	@media screen and (max-width: 700px) {
		display: none;
	}
}
#the-drip-title {
	position: absolute;
	bottom: 160px;
	left: 20px;
	display: block;
	text-align: left;
	width: 175px;
	height: 50px;
	font: 400 40px/40px $font_TR;
	color: $black;
}
#the-drip {

}
#social-stream {
	width:120%;
	margin:5px;
	position:absolute;
	bottom:1px;
	height:160px;
	z-index:5;
	overflow:hidden;
	.stream li {
		background-color: rgba(0,0,0,0.5);
		opacity: 1 !important;
		.section-intro, .filter a:hover {
			background-color: $black;
		}
		.inner {
			border-top: 3px solid $black;
			border-left: 3px solid $black;
			border-right: 3px solid $black;
		}
	}
}
.dcsns-li {
	zoom: 1;
	filter: alpha(opacity=50);
	opacity: 0.5;
}
#footer-legal {
	position: absolute;
	bottom: 1px;
	left: 20px;
	font: 400 7px/7px $font_basic;
	color: $black;
}
/*---------- Colorbox --------------------------------------------------------*/
/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0 !important; width:100% !important;  z-index:9999; overflow:hidden;}
#cboxWrapper {max-width:none;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative; margin:0 auto; float:none !important;}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0; padding:0; margin:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{background:#000; opacity: 0.7 !important; filter: alpha(opacity = 70);}
#colorbox{outline:0;}
    #cboxContent{background:#fff; overflow:hidden;border:10px solid #231f20;}
        .cboxIframe{background:#fff;}
        #cboxError{padding:50px; border:1px solid #ccc;}
        #cboxTitle{position:absolute; bottom:4px; left:0; text-align:center; width:100%; color:#949494;}
        #cboxCurrent{position:absolute; bottom:4px; left:58px; color:#949494;}
        #cboxLoadingOverlay{background:url(images/loading_background.png) no-repeat center center;}
        #cboxLoadingGraphic{background:url(images/loading.gif) no-repeat center center;}

        /* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
        #cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {border:0; padding:0; margin:0; overflow:visible; background:none; }

        /* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
        #cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}

        #cboxSlideshow{position:absolute; bottom:4px; right:30px; color:#0092ef;}
        #cboxPrevious{position:absolute; top:50%; left:0; margin-top:-35px; padding:35px; background:url(images/left-arrow.png) no-repeat center; width:35px; height:35px; text-indent:-9999px;}
        #cboxNext{position:absolute; top:50%; right:0px; margin-top:-35px; padding:35px; background:url(images/right-arrow.png) no-repeat center; width:35px; height:35px; text-indent:-9999px;}
        #cboxClose{position:absolute; top:0; right:0; background:url(images/close.png) no-repeat center; width:44px; height:43px; text-indent:-9999px;}

/*
  The following fixes a problem where IE7 and IE8 replace a PNG's alpha transparency with a black fill
  when an alpha filter (opacity change) is set on the element or ancestor element.  This style is not applied to or needed in IE9.
  See: http://jacklmoore.com/notes/ie-transparency-problems/
*/
.cboxIE #cboxTopLeft,
.cboxIE #cboxTopCenter,
.cboxIE #cboxTopRight,
.cboxIE #cboxBottomLeft,
.cboxIE #cboxBottomCenter,
.cboxIE #cboxBottomRight,
.cboxIE #cboxMiddleLeft,
.cboxIE #cboxMiddleRight {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF);
}
#cboxOverlay, #cboxWrapper, #colorbox {
    position: absolute;
    top: 0;
    left: 0!important;
    width: 100%!important;
    z-index: 9999;
    overflow: hidden;
}
#cboxOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .7!important;
    filter: alpha(opacity=70);
}
#cboxContent {
    overflow: hidden;
    border: 10px solid #231f20;
	position: relative;
    margin: 0 auto;
    float: none!important;
}
#cboxClose, #cboxNext, #cboxPrevious, #cboxSlideshow {
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    overflow: visible;
    background: 0 0;
}
#cboxClose {
    position: absolute;
    top: 0;
    right: 0;
    background: url('img/close.png') center no-repeat;
    width: 44px;
    height: 43px;
    text-indent: -9999px;
}
#cboxNext, #cboxPrevious {
    text-indent: -9999px;
    position: absolute;
    top: 50%;
    margin-top: -35px;
    padding: 35px;
    width: 35px;
    height: 35px;
}
#cboxPrevious {
    left: 0;
    background: url('img/left-arrow.png') center no-repeat;
}
#cboxNext {
    right: 0;
    background: url('img/right-arrow.png') center no-repeat;
}
#cboxLoadedContent iframe {
    width: 1px;
    min-width: 100%;
    min-height: 100%;
}


/*----------------------------------------------------------------------------*/
/*	MOBILE NAV
/*----------------------------------------------------------------------------*/

/* general */
$mobileNav_fontFamily: Helvetica, Arial, sans-serif;

/* tab styling */
$mobileNav_tabWidth: 50px;
$mobileNav_tabHeight: 50px;
$mobileNav_tabTopSpacing: 5px;
$mobileNav_tabBottomSpacing: 10px;
$mobileNav_tabLineHeight: 4px;
$mobileNav_tabFont: 100 10px/10px $mobileNav_fontFamily;
$mobileNav_tabLetterSpacing: 0.03em;
$mobileNav_tabBkgrdColor: transparent;
$mobileNav_tabFontColor: #000;
$mobileNav_tabLineColor: #000;
$mobileNav_tabOpenBkgrdColor: #222;
$mobileNav_tabOpenFontColor: #fff;
$mobileNav_tabOpenLineColor: #fff;

/* menu items */
$mobileNav_menuWidth: 270px;
$mobileNav_menuPadLeftRight: 18px;
$mobileNav_menuBkGrdColor: #222;
$mobileNav_menuTitleFont: 300 12px/12px $mobileNav_fontFamily;
$mobileNav_menuTitleLetterSpacing: 0.05em;
$mobileNav_menuTitleFontColor: #888;
$mobileNav_menuItemsFont: 300 15px/30px $mobileNav_fontFamily;
$mobileNav_menuItemsFontColor: #fff;
$mobileNav_menuItemsLetterSpacing: 0.03em;
$mobileNav_menuItemsLineColor: #888;
$mobileNav_menuItemsAltBgColor: $sn_red;
$mobileNav_menuItemsAltTxColor: #222;

/* submenu items */
$mobileNav_subMenuWidth: 220px;
$mobileNav_subMenuPadLeftRight: 18px;
$mobileNav_subMenuBkgrdColor: #c2601a;
$mobileNav_subMenuItemsBkgrdColor: #700;
$mobileNav_subMenuItemsBkgrdColorHover: #500;
$mobileNav_subMenuItemsFont: 300 15px/30px $mobileNav_fontFamily;
$mobileNav_subMenuItemsFontColor: #fff;
$mobileNav_subMenuItemsLetterSpacing: 0.03em;
$mobileNav_subMenuItemsLineColor: #888;
$mobileNav_subMenuItemsAltBgColor: #500;
$mobileNav_subMenuItemsAltTxColor: #000;

#mobileNav {
	display: none;
	width: $mobileNav_menuWidth;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: $mobileNav_menuBkGrdColor;
	font-family: $mobileNav_fontFamily;
	text-transform: uppercase;
	z-index: 2000;
	transform: translate($mobileNav_menuWidth, 0);
	transition: all 200ms ease;
	@media screen and (max-width: 700px) {
		display: block;
	}

	.tab {
		box-sizing: border-box;
		position: absolute;
		top: 6px;
		left: -50px;
		height: $mobileNav_tabHeight;
		width: $mobileNav_tabWidth;
		background-color: $mobileNav_tabBkgrdColor;
		color: $mobileNav_tabFontColor;
		padding-top: $mobileNav_tabTopSpacing;
		font: $mobileNav_tabFont;
		letter-spacing: $mobileNav_tabLetterSpacing;
		text-transform: uppercase;
		text-align: center;
		transform: translate(0, 0);
		transition: all 200ms ease;
		&:before {
			content: '';
			position: absolute;
			bottom: 18px;
			left: 10px;
			right: 10px;
			height: $mobileNav_tabLineHeight;
			border-top: $mobileNav_tabLineColor solid $mobileNav_tabLineHeight;
			border-bottom: $mobileNav_tabLineColor solid $mobileNav_tabLineHeight;
			transition: all 200ms ease-in-out;
		}
		&:after {
			content: '';
			position: absolute;
			bottom: $mobileNav_tabBottomSpacing;
			left: 10px;
			right: 10px;
			border-bottom: $mobileNav_tabLineColor solid $mobileNav_tabLineHeight;
			transition: all 200ms ease-in-out;
		}
	}
	.main {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow-y: auto;
		padding: 24px 2px;
		width: 266px;
		overflow-x: hidden;
	}
	.group {
		margin-bottom: 50px;
		text-align: left;
	}
	.groupTitle {
		padding: 0px $mobileNav_menuPadLeftRight;
		font: $mobileNav_menuTitleFont;
		letter-spacing: $mobileNav_menuTitleLetterSpacing;
		color: $mobileNav_menuTitleFontColor;
		margin-bottom: 10px;
	}
	.groupMenu {
		list-style: none;
		padding-left: 0;
		li {
			border-bottom: 1px solid $mobileNav_menuItemsLineColor;
			margin-bottom: 0;
			&.current {
				background-color: $mobileNav_menuItemsAltBgColor;
				a {
					color: $mobileNav_menuItemsAltTxColor;
				}
				.subGroupTitle {
					color: $mobileNav_menuItemsAltTxColor;
				}
			}
			&:hover {
				background-color: $mobileNav_menuItemsAltBgColor;
				a {
					color: $mobileNav_menuItemsAltTxColor;
				}
				.subGroupTitle {
					color: $mobileNav_menuItemsAltTxColor;
				}
			}
		}
		a {
			display: block;
			padding: 5px $mobileNav_menuPadLeftRight;
			font: $mobileNav_menuItemsFont;
			letter-spacing: $mobileNav_menuTitleLetterSpacing;
			color: $mobileNav_menuItemsFontColor;
		}
	}
	.subGroup {
		cursor: pointer;
		position: static;
		margin-bottom: 0;
	}
	.subGroupTitle {
		display: block;
		font: $mobileNav_menuItemsFont;
		letter-spacing: $mobileNav_menuTitleLetterSpacing;
		color: $mobileNav_menuItemsFontColor;
		padding: 5px $mobileNav_menuPadLeftRight;
	}
	.subGroupMenu {
		padding: 50px 0px;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: auto;
		list-style: none;
		text-transform: uppercase;
		max-height: 100%;
		height: 100%;
		overflow: hidden;
		z-index: 2010;
		width: $mobileNav_subMenuWidth;
		background-color: $mobileNav_subMenuBkgrdColor;
		transform: translate($mobileNav_subMenuWidth, 0);
		transition: all 200ms ease;
		li {
			border-bottom: 1px solid $mobileNav_subMenuItemsLineColor;
			background-color: $mobileNav_subMenuItemsBkgrdColor;
			transition: all 200ms ease;
			&.current {
				background-color: $mobileNav_subMenuItemsAltBgColor;
				a.subItemAncor {
					color: $mobileNav_subMenuItemsAltTxColor;
				}

			}
			&:hover {
				background-color: $mobileNav_subMenuItemsAltBgColor;
				a.subItemAncor {
					color: $mobileNav_subMenuItemsAltTxColor;
				}
			}
		}
		a.subItemAncor {
			display: block;
			padding: 5px $mobileNav_subMenuPadLeftRight;
			font: $mobileNav_subMenuItemsFont;
			letter-spacing: $mobileNav_subMenuItemsLetterSpacing;
			color: $mobileNav_subMenuItemsFontColor;
		}
	}
	.subGroup.show {
		.subGroupMenu {
			display:block;
			transform: translate(0, 0);
		}
		&:before {
			content:'';
			background-color:rgba(0,0,0,.7);
			position:fixed;
			top:0;left:0;right:0;bottom:0;
		}
	}
	.phone {
		-webkit-hyphens: none;
	}
}
body.showMobileNav {
	#mobileNav {
		transform: translate(0, 0);

		.tab {
			background-color: $mobileNav_tabOpenBkgrdColor;
			color: $mobileNav_tabOpenFontColor;

			&:before {
				bottom: 16px;
				height: 0px;
				border-top: none;
				border-bottom: $mobileNav_tabOpenLineColor solid $mobileNav_tabLineHeight;
				transform: rotate(45deg);
			}
			&:after {
				bottom: 16px;
				border-bottom: $mobileNav_tabOpenLineColor solid $mobileNav_tabLineHeight;
				transform: rotate(-45deg);
			}
		}
	}
	&:after {
		content:'';
		background-color:rgba(0,0,0,.7);
		position:fixed;
		top:0;left:0;right:0;bottom:0;
		z-index:1000;
	}
}
